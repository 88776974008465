import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const IconMarker = () => (
  <div className="single__map__marker">
    <span></span>
  </div>
);

class SingleMap extends Component {
  static defaultProps = {
    center: {
      lat: 51.65,
      lng: 17.81
    },
    zoom: 15
  };

  render() {
    return (
      <div className="single__map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyChAGiNYPrtfzMIFN9KMZHjq8hm_dHKEwQ' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <IconMarker lat={ this.props.lat } lng={ this.props.lng }/>
        </GoogleMapReact>
      </div>
    );
  }
}

export default SingleMap;